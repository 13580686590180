import axios from "axios";
import config from './config'
import {Toast} from "vant";
import {Message} from 'element-ui';
import router from "@/router";
// axios.defaults.headers['Access-Control-Allow-Origin'] = '*'
const request = axios.create({
    baseURL: "https://ams.jsjsem.cn/",
    // baseURL: "https://jsapi.thingsworld.com.cn:8090/",
    timeout: config.timeout,
    withCredentials: config.withCredentials,
    headers: {
        "Authorization": 'Bearer ' + localStorage.getItem('access-token'),
    }
})

request.interceptors.response.use(res => {
    if (res.data.code === 401) {
        localStorage.removeItem('access-token')
    }
    if (res.status === 200 && res.data.code === 0) {
        // Toast({ message: res.data.msg })
        return res.data
    } else if (res.data.code !== 0) {
        if(!res.config.url.includes('customer_users/info_phone')){
            Toast({message: res.data.msg,duration:2000})
        }
        return res.data
    }
}, err => {
    // Toast({message: err.data.response.msg})
    return err
})




export default request
