import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import { Toast } from 'vant'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: `/home`
  },
  {
    path: '/home',
    name: 'home',
    component: Home
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '@/views/Login.vue')
  },
  {
    path: '/faultForm',
    name: 'faultForm',
    component: () => import(/* webpackChunkName: "faultForm" */ '@/views/FaultForm.vue')
  },
  {
    path: '/maintenance',
    name: 'maintenance',
    component: () => import(/* webpackChunkName: "maintenance" */ '@/views/Maintenance.vue')
  },
  {
    path: '/processList',
    name: 'processList',
    component: () => import(/* webpackChunkName: "processList" */ '@/views/processList.vue')
  },
  {
    path: '/detail',
    name: 'detail',
    component: () => import(/* webpackChunkName: "detail" */ '@/views/detail.vue')
  },
  {
    path: '/agreement',
    name: 'agreement',
    component: () => import(/* webpackChunkName: "agreement" */ '@/views/agreement.vue')
  },

]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})
//解决路由更新之后重定向报错问题
// push
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}



// replace
const originalReplace = VueRouter.prototype.replace
VueRouter.prototype.replace= function replace(location) {
  return originalReplace.call(this, location).catch(err => err)

}
router.beforeEach((to, from, next) => {
  let token = localStorage.getItem('access-token')
  if (!token && to.path === '/faultForm') {
    Toast('请先登录')
    next('/login')
    return
  } else if (!token && to.path === '/maintenance') {
    Toast('请先登录')
    next('/login')
    return
  }else if(!token && to.path === '/processList'){
    Toast('请先登录')
    next('/login')
  } else if (to.path === '/login' && token) {
    next('/')
  }
  next()
})

export default router
