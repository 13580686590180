<template>
  <div class="home">
    <div class="top">
      嘉盛环境
    </div>
    <div class="bannerWrap">
      <img
          :src="require('../assets/banner.png')"
          :lowsrc="require('../assets/low1.jpg')"
      />
    </div>

  <div >
    <div class="home-self-service">
      <div class="home-self-service-title">自助服务</div>
      <div class="home-self-service-content">
        <van-grid >
          <van-grid-item :icon="require('../assets/repair.png')"  text="客户报修" @click="cusWarranty"  />
          <van-grid-item :icon="require('../assets/complain.png')"text="意见投诉" @click="mainService" />
          <van-grid-item :icon="require('../assets/process.png')"text="查看进度" @click="expect" />
        </van-grid>
      </div>
    </div>
  </div>

    <div class="factoryImg">
      <img
          :src="require('../assets/factory.png')"
          :lowsrc="require('../assets/low2.jpg')"
      />
    </div>

    <div class="customer" v-if="customerInfo.customer_name">
        <span>客户名称：{{customerInfo.customer_name}}</span>
    </div>
    </div>
</template>

<script>
import { Toast } from 'vant'
import request from '@/http/axios'

export default {
  data() {
    return {
      phone:null,
      customerInfo: {}
    }
  },
  methods: {

    //查看进度
    expect(){
      this.$nextTick(() => {
        this.$router.push('/processList')
      })
      // Toast("敬请期待")
    },
    // 客户保修
    cusWarranty() {
      localStorage.setItem("type", "客户报修")
        this.$nextTick(() => {
          this.$router.push('/faultForm')
        })
      // }
    },
    // 维保服务
    mainService() {
      localStorage.setItem("type", "投诉建议")
        this.$nextTick(() => {
          this.$router.push('/maintenance')
        })
      // }
    },
    // 获取产品基本信息
    getCustomerInfo() {
      // const toast = Toast.loading({
      //   duration: 0, // 持续展示 toast
      //   forbidClick: true,
      //   message: "加载中..."
      // });
      if(this.phone){
        request.get(`/customer_users/info_phone/${this.phone}`,{headers: {"Authorization": 'Bearer ' + localStorage.getItem('access-token')}}).then(res => {
          if(res?.data){
            this.customerInfo = res.data
          }
          // toast.clear()
        })
      }

    }
  },
  created() {
    this.phone = localStorage.getItem('phone')
    // localStorage.getItem('access-token') ? localStorage.setItem('id', this.$route.params.id) : localStorage.setItem('id', this.$route.params.id)
    this.getCustomerInfo()
  }
}
</script>

<style lang="less" scoped>
.home {
  width: 100%;
  margin: 0 auto;

  .home-self-service {
    box-sizing: border-box;
    /deep/ [class*=van-hairline]::after {
      border: none
    }
  }
}
.home-self-service{
  padding: 15px;
  .home-self-service-title{
    font-size: 15px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #101010;
  }
  .home-self-service-content{
    font-size: 12px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #101010;
    padding: 0 11px;
    /deep/img{
      width: 64px;
      height: 64px;
    }
    /deep/span{
      font-size: 12px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #101010;
    }
  }
}

.top{
  width: 100vw;
  height: 5vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #005EDF;
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: 600;
  color: #FFFFFF;
}
.factoryImg{
  img{
    display: block;
    width: 92vw;
    //height: 150px;
    margin: 0 auto;
    border-radius: 5px;
    overflow: hidden;
  }

}
.customer{
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #101010;
}
.bannerWrap{
   img{
    width: 100%;
  }
}
</style>
